import { useTracker } from '@/composables/tracker'
import PaymentGateway from '@/constants/payment-gateway'
import useAppStore from '@/stores/app'
import useAuthStore from '@/stores/auth'
import useProductStore from '@/stores/product'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const useCartStore = defineStore('cart', () => {
  const { trackRemoveFromCart } = useTracker()

  const appStore = useAppStore()
  const authStore = useAuthStore()
  const productStore = useProductStore()

  const defaultCart = {
    item_subtotal: 0,
    items: null,
    payment_gateway: PaymentGateway.PAYPAL,
    payment_processing_fee: 0,
    platform_fee: 0,
    shipping_address_id: null,
    shipping_address: null,
    shipping_provider: null,
    shipping_service: null,
    shipping_cost: 0
  } as Cart

  // define the local storage key for shopping
  const keyElement = [appStore.storagePrefix, 'cart']
  if (Object.keys(authStore.user).length) {
    keyElement.push(String(authStore.user.id))
  }
  const cartKey = keyElement.join('_')

  const termsAndConditions = ref(false)
  const notes = ref<string | null>(null)
  const selectedItem = ref<CartItem | null>(null)
  const loading = ref(false)
  const checkingOut = ref(false)
  const showPayPalButtons = ref(false)
  const shippingUnavailable = ref(false)
  const validShippingAddress = ref(false)
  const quantity = ref(1)
  const cart = useLocalStorage(cartKey, defaultCart)
  const intent = ref<CartIntent | null>(null)

  const itemCount = computed(() => cart.value.items?.length || 0)

  const selectedItemCount = computed(
    () => cart.value.items?.filter((item) => item.is_selected).length || 0
  )

  const productExistedInCart = computed(() => {
    return cart.value.items?.find((item) => item.product_id === productStore.selectedProduct?.id)
  })

  const isCurrencyConverted = computed(() => {
    if (
      PaymentGateway.PAYPAL === cart.value.payment_gateway &&
      appStore.currency.selected !== appStore.paypal.currency
    ) {
      return true
    }

    if (
      PaymentGateway.BAYARIND === cart.value.payment_gateway &&
      appStore.currency.selected !== appStore.bayarind.currency
    ) {
      return true
    }

    return false
  })

  const addItem = ({ product, quantity, notes = null }: CartAddItemOptions) => {
    if (!cart.value.items) {
      cart.value.items = []
    }

    cart.value.items.push({
      notes: notes,
      product_id: Number(product.id),
      product: product,
      quantity: quantity,
      total: product.price * quantity,
      is_selected: true
    })

    // TRIGGER ADD TO CART TO BACKEND
    const addedItem = cart.value.items.find((item) => item.product_id === product.id)
    if (addedItem) {
      selectedItem.value = addedItem
      intent.value = CartIntent.AddItem
    }
  }

  const checkoutItem = ({ product, quantity, notes = null }: CartAddItemOptions) => {
    if (!cart.value.items) {
      cart.value.items = []
    }

    cart.value.items = cart.value.items?.map((item: CartItem) => ({ ...item, is_selected: false }))

    if (cart.value.items?.find((item) => item.product_id === product.id)) {
      cart.value.items = cart.value.items.map((item: CartItem) => {
        if (item.product_id === product.id) {
          return {
            ...item,
            is_selected: true,
            quantity,
            notes,
            total: item.product.price * quantity
          }
        }

        return item
      })
    } else {
      cart.value.items.push({
        notes: notes,
        product_id: Number(product.id),
        product: product,
        quantity: quantity,
        total: product.price * quantity,
        is_selected: true
      })
    }

    // TRIGGER CHECKOUT ITEM TO BACKEND
    intent.value = CartIntent.CheckoutItem
  }

  const updateItem = ({ product, quantity = 0, notes = null }: CartUpdateItemOptions) => {
    if (!cart.value.items) {
      return
    }

    cart.value.items = cart.value.items?.map((item) => {
      if (item.product_id === product.id) {
        const total = item.product.price * quantity
        return {
          ...item,
          notes,
          quantity,
          total
        }
      }

      return item
    })

    // TRIGGER CART ITEM UPDATE TO BACKEND
    const updatedItem = cart.value.items.find((item) => item.product_id === product.id)
    if (updatedItem) {
      intent.value = CartIntent.Synchronize
    }
  }

  const deleteItem = (productId: number) => {
    if (!cart.value.items) {
      return
    }

    const product = cart.value.items
      .filter((item) => item.product !== undefined)
      .find((item) => item.product_id === productId)?.product

    const value = cart.value.items.find((item) => item.product_id === productId)?.total || 0

    if (product) {
      trackRemoveFromCart([product], value)
    }

    // TRIGGER CART ITEM DELETE TO BACKEND
    const deletedItem = cart.value.items.find((item) => item.product_id === productId)
    if (deletedItem) {
      cart.value.items = cart.value.items.filter((item) => item.product_id !== productId)
      selectedItem.value = deletedItem
      intent.value = CartIntent.DeleteItem
    }
  }

  const syncCart = () => {
    intent.value = CartIntent.Synchronize
  }

  const deleteSelected = () => {
    if (cart.value.items) {
      cart.value.items = cart.value.items.filter((item) => !item.is_selected)
    }
  }

  const clearCart = (purchase = false) => {
    if (!purchase && cart.value.items) {
      const products = cart.value.items
        .filter((item) => item.product !== undefined)
        .map((item) => item.product as Product)

      if (products.length) {
        trackRemoveFromCart(products, cart.value.item_subtotal)
      }
    }

    cart.value = defaultCart
    intent.value = CartIntent.Reset
  }

  return {
    defaultCart,
    cart,
    intent,
    isCurrencyConverted,
    itemCount,
    selectedItemCount,
    loading,
    checkingOut,
    notes,
    productExistedInCart,
    quantity,
    selectedItem,
    shippingUnavailable,
    validShippingAddress,
    showPayPalButtons,
    termsAndConditions,
    addItem,
    checkoutItem,
    updateItem,
    deleteItem,
    syncCart,
    deleteSelected,
    clearCart
  }
})

export enum CartIntent {
  Get = 'get',
  Synchronize = 'synchronize',
  Reset = 'reset',
  AddItem = 'add-item',
  CheckoutItem = 'checkout-item',
  UpdateItem = 'update-item',
  DeleteItem = 'delete-item',
  AddressChanged = 'address-changed'
}
export default useCartStore
